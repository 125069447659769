import React, { useState, useEffect } from "react";
import { Container, Row, Col, Image, Button } from "react-bootstrap";
import { Link } from "react-scroll";
import styled from "styled-components";

import Layout from "../components/Layouts/MainLayout";
import Seo from "../components/Seo";

// import SubFormWrapper from "../components/Forms/Sub";

import USCapitol from "@images/us-capitol.png";
import USA_Flag from "@images/usa-flag2.jpg";
import ElectionCoverage from "@images/election_coverage.gif";
import AcrossAmerica from "@images/across-america.png";
import WEB3 from "@images/web3.png";
import Mount_Rushmore from "@images/mount_rushmore.png";
import PollingStation from "@images/polling-station.png";
import Handshake from "@images/handshake.png";

const Calendly = () => {
    useEffect(() => {
      const head = document.querySelector('head');
      const script = document.createElement('script');
      script.setAttribute('src', 'https://assets.calendly.com/assets/external/widget.js');
      head.appendChild(script);
  
      // Cleanup on component unmount
      return () => {
        head.removeChild(script);
      };
    }, []);
  
    return (
      <div id="schedule_form">
        <div
          className="calendly-inline-widget"
          data-url="https://calendly.com/vngle/30minute_partner_informational?hide_event_type_details=1&hide_gdpr_banner=1"
          style={{ minWidth: '320px', height: '700px' }}
        />
      </div>
    );
  };

const Election = () => {
    const [timeRemaining, setTimeRemaining] = useState('');

  useEffect(() => {
    const electionDate = new Date("November 5, 2024 00:00:00").getTime();
    const countdownFunction = setInterval(() => {
      const now = new Date().getTime();
      const distance = electionDate - now;

      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      if (distance > 0) {
        setTimeRemaining(
            <div style={{ display: 'flex', fontSize: 'calc(1vw + 15px)' }}>
                <CountdownWrapper>Election Countdown:</CountdownWrapper>
                <Time>
                    {days}d {hours}h {minutes}m {seconds}s
                </Time>
            </div>
        );
      } else {
        clearInterval(countdownFunction);
        setTimeRemaining('The election has started!');
      }
    }, 1000);

    return () => clearInterval(countdownFunction);
  }, []);
  return (
    <Layout>
      <Seo
        title="A Grassroots News Agency"
        description="Vngle is a decentralized grassroots news network covering pressing underrepresented verified coverage on cities across America. Our coverage is for the people, by the people."
      />
      <section style={{ backgroundColor: '#f5f5f5'}}>
        <section style={{ 
            position: 'relative',
            width: 'auto', 
            height: '85vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundImage: `url(${USCapitol})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center 100%',
            backgroundRepeat: 'no-repeat',
        }}>
            {/* Overlay */}
            <Container fluid style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            background: 'rgba(0, 0, 0, 0.01)',
            zIndex: 1,
            }}></Container>
            
            <ResponsiveContainer fluid style={{ 
            position: 'relative',
            zIndex: 2, 
            height: '100vh',
            }}>
            <Col>
                <Row>
                <h1 style={{
                    paddingTop: 'calc(5vh + 100px)',
                    color: '#000080',
                    fontSize: 'calc(2vw + 20px)',
                    fontStyle: 'normal',
                    fontWeight: 'bold',
                    lineHeight: 'normal',
                    letterSpacing: '-0.084px',
                }}>
                    Expand your grassroots-level election insights <br/> across overlooked America. 
                </h1>
                </Row>
                <Row>
                <p style={{
                    color: '#212529',
                    fontFamily: 'Inter',
                    fontSize: 'calc(0.8vw + 12px)',
                    fontStyle: 'italic',
                    fontWeight: 400,
                    lineHeight: '40.669px',
                }}>
                    Accelerate your visual content and insights on key communities that are going underrepresented.
                </p>
                </Row>
                <Row>
                    <Link
                        to="calendlyComponent"
                        spy={true}
                        smooth={true}
                        offset={-165}
                        duration={500}
                    >
                        <StyledButton>
                            Book a Meeting
                        </StyledButton>
                    </Link>
                </Row>
            </Col>
            </ResponsiveContainer>
        </section>
        <ResponsiveContainer fluid style={{
                background: 'linear-gradient(90deg, rgba(240, 124, 139, 0.35) 0%, rgba(150, 205, 239, 0.35) 100%)',
                height: '91.964px',
                display: 'flex',
                justifyContent: 'left',
                alignItems: 'center',
            }}>
                {timeRemaining}
        </ResponsiveContainer>
        <section style={{
            width: '100%', 
            height: '100%', 
            margin: 0, 
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        }}>
            <ResponsiveContainer fluid style={{
                    width: '100%', 
                    height: 'auto',
                    paddingTop: 'calc(5vw + 50px)',
                    paddingBottom: 'calc(5vw + 50px)',
                    position: 'relative',
                    overflow: 'hidden'
            }}>
                <StyledRow style={{ display: 'flex' }}>
                    <Col xs={{ order: 2 }}style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <HoverableHeader style={{
                        background: 'linear-gradient(90deg, rgba(240, 124, 139, 0.35) 0%, rgba(150, 205, 239, 0.35) 100%)',
                        borderRadius: '12px',
                        height: '100%',
                        alignContent: 'center',
                    }}>
                        Leading up to the election, Vngle is aiding partners in covering the needs of overlooked Americans. Work with us to grow your engagement across some of the most marginalized communities across the battleground states.
                    </HoverableHeader>
                    </Col>
                    <SpecialCol xs={{ order: 1 }} style={{ display: 'flex' }}>
                        <ResponsiveImage src={ElectionCoverage} fluid style={{
                            borderRadius: '12px',
                            flexShrink: 0
                        }}/>
                    </SpecialCol>
                </StyledRow>
                <StyledRow style={{ display: 'flex', paddingTop: 'calc(2.5vw + 50px)' }}>
                    <SpecialCol2 lg={{ order: 2 }} style={{ display: 'flex' }}>
                        <ResponsiveImage src={AcrossAmerica} fluid style={{
                            borderRadius: '12px',
                            flexShrink: 0
                        }}/>
                    </SpecialCol2>
                    <Col lg={{ order: 1 }} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <HoverableHeader style={{
                        background: 'linear-gradient(90deg, rgba(240, 124, 139, 0.35) 0%, rgba(150, 205, 239, 0.35) 100%)',
                        borderRadius: '12px',
                        height: '100%',
                        alignContent: 'center'
                    }}>
                        Our content is secured by patented mobile forensics verification technology.
                    </HoverableHeader>
                    </Col>
                </StyledRow>
                <StyledRow style={{ display: 'flex', paddingTop: 'calc(2.5vw + 50px)' }}>
                    <Col xs={{ order: 2 }} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <HoverableHeader style={{
                        background: 'linear-gradient(90deg, rgba(240, 124, 139, 0.35) 0%, rgba(150, 205, 239, 0.35) 100%)',
                        borderRadius: '12px',
                        height: '100%',
                        alignContent: 'center'
                    }}>
                        Our tech enables us to share a record of exactly where, when, and how our content is captured, helping you get transparent insights from the ground without being there.
                    </HoverableHeader>
                    </Col>
                    <SpecialCol xs={{ order: 1 }} style={{ display: 'flex' }}>
                        <ResponsiveImage src={WEB3} fluid style={{
                            borderRadius: '12px',
                            flexShrink: 0
                        }}/>
                    </SpecialCol>
                </StyledRow>
                <SpecialRow style={{ display: 'flex', flexDirection: 'row' }}>
                    <Col md={5}></Col>
                    <Col md={7} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <ResponsiveHeader style={{ fontFamily: 'Oleo Script' }}>
                        Our infrastructure enables you to re-imagine how you represent communities from the point of view of locals who leave there. Get insights on everything from polling stations to community rallies.
                    </ResponsiveHeader>
                    </Col>
                </SpecialRow>
                <Row>
                    <div style={{ margin: '0 calc(-5vw - 50px)' }}>
                        <Image fluid src={Mount_Rushmore} style={{
                            width: 'calc(100% + 10vw + 100px)',
                            height: 'auto',
                            position: 'absolute',
                            bottom: 0,
                            left: 0,
                        }}/>
                    </div>
                </Row>
            </ResponsiveContainer>
        </section>
        <Container fluid style={{
            display:'flex',
            justifyContent: 'center',
        }}>
            <Image fluid src={PollingStation}/>
        </Container>
        <Container fluid style={{
                background: `url(${USA_Flag}) lightgray 50% / cover no-repeat`,
                height: '91.964px',
            }}>
        </Container>
        <ResponsiveContainer fluid style={{
            width: '100%', 
            height: 'auto',
            paddingTop: 'calc(5vw + 50px)',
            paddingBottom: 'calc(5vw + 50px)',
        }}>
            <Row style={{ display: 'flex', flexDirection: 'column'}}>
                <Col style={{ display: 'flex', justifyContent: 'center' }}>
                    <ResponsiveHeader>
                        We believe in sharing the American experience from all walks of life. We’re eager to support partners who want to cover more of rural America.
                    </ResponsiveHeader>
                </Col>
                <Col style={{ display: 'flex', justifyContent: 'center' }}>
                    <Image fluid src={Handshake} style={{ paddingTop: 'calc(2.5vw + 25px)' }}/>
                </Col>
            </Row>
        </ResponsiveContainer>
        <Container fluid style={{
                background: `url(${USA_Flag}) lightgray 50% / cover no-repeat`,
                height: '91.964px',
            }}>
        </Container>
        <ResponsiveContainer fluid>
            <Row id="calendlyComponent">
                <Col className="d-flex justify-content-center" style={{paddingTop: '6rem'}}>
                    <ResponsiveHeader>
                        Schedule a 30-Minute Partner Informational Session with Vngle Grassroots News Agency
                    </ResponsiveHeader>
                </Col>
                <Col><Calendly /></Col>
            </Row>
        </ResponsiveContainer>
        {/* <Container fluid>
            <SubFormWrapper />
        </Container> */}
        </section>
    </Layout>
  );
};

export default Election;

const ResponsiveContainer = styled(Container)`
    && {
        padding-right: calc(7.5vw + 75px);
        padding-left: calc(7.5vw + 75px);
    }

    @media (max-width: 992px) {
        && {
            padding-right: calc(2.5vw + 25px);
            padding-left: calc(2.5vw + 25px);
        }
    }
`;
const ResponsiveHeader = styled.h1`
    && {
        font-size: calc(1vw + 15px);
        color: #212529;
        font-family: Inter;
        font-style: normal;
        font-weight: bold;
        letter-spacing: 0.68px;
    }

    @media (max-width: 992px) {
        && {
            font-size: calc(2vw + 15px);
            margin-top: 25px;
            text-align: center;
        }
    }
`;
const HoverableHeader = styled(ResponsiveHeader)`
    && {
        padding: 50px 50px;
    }
    @media (max-width: 992px){
        && {
            padding: 50px 25px;
            text-align: center;
        }
    }
    transition: 0.5s ease;
    cursor: default;

    &:hover {
    box-shadow:
        1px 1px 0px rgba(239, 181, 181, 0.7),
        2px 2px 0px rgba(219, 161, 161, 0.7),
        3px 3px 0px rgba(199, 141, 141, 0.7),
        4px 4px 0px rgba(179, 121, 121, 0.7),
        5px 5px 0px rgba(159, 101, 101, 0.7),
        6px 6px 0px rgba(139, 81, 81, 0.7),
        7px 7px 0px rgba(119, 61, 61, 0.7),
        8px 8px 0px rgba(99, 41, 41, 0.7),
        9px 9px 0px rgba(79, 21, 21, 0.7);
    transform: translateX(-3px);
    }
`;
const ResponsiveImage = styled(Image)`
    && {
        width: calc(25vw + 200px);
        height: calc(25vw + 200px);
    }
    transition: 0.5s ease;
    cursor: default;

    &:hover {
    box-shadow:
        1px 1px 0px rgba(185, 206, 229, 0.7),
        2px 2px 0px rgba(165, 186, 209, 0.7),
        3px 3px 0px rgba(145, 166, 189, 0.7),
        4px 4px 0px rgba(125, 146, 169, 0.7),
        5px 5px 0px rgba(105, 126, 149, 0.7),
        6px 6px 0px rgba(85, 106, 129, 0.7),
        7px 7px 0px rgba(65, 86, 109, 0.7),
        8px 8px 0px rgba(45, 66, 89, 0.7),
        9px 9px 0px rgba(25, 46, 69, 0.7);
    transform: translateX(-3px);
    }

    @media (max-width: 992px) {
        && {
        width: calc(75vw + 100px);
        height: calc(75vw + 100px);
        }
    }
`;
const StyledRow = styled(Row)`
    display: flex;
    flex-direction: row;

    @media (max-width: 992px) {
        flex-direction: column;
    }
`;
const SpecialRow = styled(Row)`
    && {
        padding-top: calc(2.5vw + 150px);
        padding-bottom: calc(15vw + 200px);
    }
    @media (max-width: 992px) {
    && {
        padding-top: calc(2.5vw + 50px);
        padding-bottom: calc(15vw + 50px);
    }
`;
const SpecialCol = styled(Col)`
    justify-content: left;

    @media (max-width: 992px) {
        justify-content: center;
    }
`;
const SpecialCol2 = styled(Col)`
    justify-content: right;

    @media (max-width: 992px) {
        justify-content: center;
    }
`;
const Time = styled.div`
    font-family: 'Oleo Script';
`;
const CountdownWrapper = styled.div`
    background: linear-gradient(to right, #FF0000, #0000FF);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    font-weight: bold;
    margin-right: 10px;
    font-family: 'Inter';
`;
const StyledButton = styled(Button)`
    && {
        padding: 7px 14px;
        color: var(--bs-dark);
        font-weight: bold;
        font-size: 1.25rem;
        transition: color 0.3s;
    }

    &:hover {
        color: var(--bs-dark);
        transition: color 0.3s;
    }
`;